<template>
  <div>
    <!-- pic652D0ED4-A37B-446C-9437-34BB6034D434.jpg -->
    <img class="rounded leftimage img-small" src="@/assets/news-network/behind-the-scenes.jpg">
    <p>
      Welcome to further behind the scenes. In this edition, LEGO Universe's design director converses about the game's currency and the value of bricks.
    </p>
    <p>
      <b>Hi again, Brian. Are there limits to LEGO Universe?</b>
    </p>
    <p>
      Players' imaginations are the only limit on how big LEGO Universe can grow. Over time, it will be entirely possible for teams of minifigs to keep opening new areas, or even entirely new worlds…
    </p>
    <p>
      But an individual minifig may have to scale back their dreams if they haven't collected or earned enough bricks to build their creations. There’s an economy at play in LEGO Universe, so you'll want to make friends and work together!
    </p>
    <p>
      <b>Can you tell us more about LEGO Universe's economy?</b>
    </p>
    <p>
      OK. Essentially, new minifigs enter LEGO Universe with nothing: no money, no bricks, no stuff. Then they go through the world completing challenges, defeating enemies, gathering goods, discovering treasures and unlocking secrets. Along the way, the minifigs find bricks and collect a highly valuable currency— plastic.
    </p>
    <p>
      Using plastic, you can buy and sell goods to do things like customize your minifig; all the shops and vendors in LEGO Universe take plastic!
    </p>
    <p>
      Since LEGO bricks are actually made of plastic, your minifig can also use it to create the bricks they need for their models or pets. But you could also trade with other minifigs for the bricks you need—so you can conserve your currency by cooperating!
    </p>
    <p>
      That's an outline of the economic forces at play in LEGO Universe… I'd love to divulge more details as the game develops!
    </p>
  </div>
</template>
